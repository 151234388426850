<template>
  <v-container grid-list-md fill-height>
    <v-layout align-center justify-center>
      <v-flex xs10 sm7 lg3>
        <v-card>
          <v-layout align-start justify-center row fill-height>
            <v-flex xs9 sm9 md9>
              <DropdownTranslate />
              <div class="icon-send">
                <v-icon size="80px">mdi-send</v-icon>
              </div>
            </v-flex>
          </v-layout>
          <v-layout align-start justify-center row fill-height>
            <v-flex xs9 sm9 md9>
              <h1>{{ $t('auth.emailSent.title') }}</h1>
            </v-flex>
          </v-layout>
          <v-layout align-start justify-center row fill-height>
            <v-flex xs9 sm9 md9>
              <p>
                {{ $t('auth.emailSent.description') }}
              </p>
            </v-flex>
          </v-layout>
          <v-layout align-end justify-space-around row fill-height>
            <router-link class="my-4" :to="{name: 'reset-password'}">
              {{ $t('auth.passwordReset.title') }}
            </router-link>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
export default {
  name: 'EmailSent',
  metaInfo() {
    return {
      title: this.$t('auth.emailSent.title'),
    };
  },
};
</script>

<style>
.icon-send {
  display: inline-flex;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 40px;
  transform: rotate(-45deg);
}
</style>
